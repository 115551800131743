import { useEffect, useState } from 'react';
import React from 'react'
import './header.css'
import { Navbar, Container, Form } from 'react-bootstrap';
import { FiBell } from "react-icons/fi";
import api from '../../service/api';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import audio from '../../sound/Bell-Ding-Sound-Effect-_download_.mp3'
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../image/clube-do-gas.png'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { GiHamburgerMenu } from "react-icons/gi";
import { StayCurrentLandscapeOutlined } from '@material-ui/icons';

const Header = () => {
    const user_id = sessionStorage.getItem('id');
    const navigate = useNavigate();

    const [userName, setUserName] = useState('');
    const [photo, setPhoto] = useState('');
    const [count, setCount] = useState('');
    const sound = document.querySelector('audio')
    const [urlAtual, setUrlAtual] = useState(window.location.pathname)
    const [widthWindow, setWidthWindo] = useState(window.innerWidth)
    const [menuGestao, setMenuGestao] = useState(false);
    const [mobile, setMobile] = useState(false)
    const [greeting, setGreeting] = useState('Bom dia')
    var newPedido = 0;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuGestao(false)
    };

    var dateNow = new Date().getHours();

    useEffect(() => {
        api
            .get("/TodosColaboradores/")
            .then((response) => {
                response.data.map(key => {
                    if (key.id == user_id) {
                        setUserName(key.name)
                        setPhoto(key.photo)
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    setInterval(() => {
        if (count >= 1) {
            sound.play()
        }
    }, 10000)

    useEffect(() => {
        if (dateNow >= 0 && dateNow <= 12) {
            setGreeting('Bom dia')
        } else if (dateNow >= 13 && dateNow <= 17) {
            setGreeting('Boa tarde')
        } else if (dateNow >= 18 && dateNow <= 23) {
            setGreeting('Boa noite')
        }
    }, [])

    const MINUTE_MS2 = 3600000;

    useEffect(() => {
        const interval = setInterval(() => {
            if (dateNow >= 0 && dateNow <= 12) {
                setGreeting('Bom dia')
            } else if (dateNow >= 13 && dateNow <= 17) {
                setGreeting('Boa tarde')
            } else if (dateNow >= 18 && dateNow <= 23) {
                setGreeting('Boa noite')
            }
        }, MINUTE_MS2);
        return () => clearInterval(interval);
    }, [])

    const MINUTE_MS = 1000;

    useEffect(() => {
        const interval = setInterval(() => {
            setUrlAtual(window.location.pathname)
            setWidthWindo(window.innerWidth)
            if (widthWindow > 500) {
                setMobile(false)
            } else {
                setMobile(true)
            }
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, []);

    return (
        <Navbar className='Navbar' expand="lg">
            <Container fluid>
                <div className="d-flex">
                    <div className='divImgHeader'>
                        <Link to='/'>
                            <img src={logo} height={"70vh"} />
                        </Link>
                    </div>
                    {!mobile ? (
                        <>
                            <div className='divHeaderBtn'>
                                <Link className='btnMenuHeader' to="/dashboard">Dashboard</Link>
                                <Link className='btnMenuHeader' to="/artes">Artes</Link>
                                <Link className='btnMenuHeader' to="/treinamentos">Treinamentos</Link>
                                <Link className='btnMenuHeader' to="/addons">Revendas</Link>
                                <Link className='btnMenuHeader' to="/colaboradores">Colaboradores</Link>
                                <Link className='btnMenuHeader' to="/faturas">Faturas</Link>
                                <Link className='btnMenuHeader' to="/solicitacoes">Solicitações</Link>
                            </div>
                            {/* {urlAtual == '/dashboard-gestao' ? (
                                <>
                                    <div className='iconContainer'>
                                        <div className='iconBell'>
                                            <Link to="/pedidos" style={{ color: 'black' }}>
                                                <Badge badgeContent={count} color="primary">
                                                    <FiBell />
                                                </Badge>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='lineVertical'>
                                        <span >|</span>
                                    </div>
                                </>
                            ) : ('')} */}
                            < Dropdown >
                                <Dropdown.Toggle className='userContainer-menu'>
                                    <img className='img' src={photo} />
                                    <div className='divInfoUser'>
                                        <span className='spanInforMornigh'>{greeting}</span>
                                        <span className='spanInforUserName'>{userName}</span>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{ left: -27, paddingLeft: 23 }}>
                                    <div>
                                        <Link className='linkSubMenu' to="/perfil">Perfil</Link>
                                    </div>
                                    <div>
                                        <Link className='linkSubMenu' to="/alterar-senha">Alterar Senha</Link>
                                    </div>
                                    <Dropdown.Divider />
                                    <div>
                                        <Link className='linkSubMenu' to='/login' onClick={() => {
                                            navigate('/', { replace: true });
                                            sessionStorage.removeItem('token')
                                            sessionStorage.removeItem('id');
                                        }} href="/">Sair</Link>
                                    </div>

                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    ) : (
                        <div>
                            <Button
                                id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <GiHamburgerMenu className='iconHeader' />
                            </Button>
                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                                <MenuItem onClick={handleClose}>
                                    <Link className='btnMenuHeader' to="/dashboard">Dashboard</Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link className='btnMenuHeader' to="/artes">Artes</Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link className='btnMenuHeader' to="/addons">Revendas</Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link className='btnMenuHeader' to="/colaboradores">Colaboradores</Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link className='btnMenuHeader' to="/Faturas">Faturas</Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link className='btnMenuHeader' to="/solicitacoes">Solicitações</Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <Link className='btnMenuHeader' onClick={() => {
                                        handleClose()
                                        sessionStorage.removeItem('token')
                                        sessionStorage.removeItem('id');
                                        navigate('/', { replace: true });
                                    }}>Sair</Link>
                                </MenuItem>
                            </Menu>
                        </div>
                    )}

                </div>
            </Container>
        </Navbar >
    )
}

export default Header