import React, { useState, useEffect } from 'react';
import './login.css'
import { Card } from 'react-bootstrap'
import logo from '../../image/clube-do-gas (grande).png'
import api from '../../service/api'
import token from "../../service/autentification";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState('');
    const [logoLogin, setLogoLogin] = useState(logo);

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post("/LoginColaborador", {
                email: email, password: password
            });
            const printAddress = async () => {
                const a = await token;
                sessionStorage.setItem('tokenNuvem', a);
            };
            printAddress();

            sessionStorage.setItem('token', response.data.token)
            sessionStorage.setItem('id', response.data.id)
            sessionStorage.setItem('typeCollaborator', response.data.type)
            window.location.href = '/dashboard'
        } catch (err) {
            if (!err.response) {
                setErrMsg('Sem resposta do servidor!');
            } else if (err.response.status === 400) {
                setErrMsg('E-mail ou senha inválido');
            }
        }

    }

    return (
        <div className='loginContainer'>
            <Card style={{ width: '60rem', height: '45rem', background: 'transparent' }}>
                <div className='modalContainer'>
                    <div className='imgContainer'>
                        <img className='loginImg' src={logoLogin} />
                    </div>
                    <div className='dateContainer'>
                        <div className="titleContainer">
                            <h1 className="titleDiv">Bem vindo ao painel administrativo</h1>
                        </div>
                        <div className="errorContainer">
                            <span>{errMsg}</span>
                        </div>
                        <form onSubmit={submitForm} className="input">
                            <input
                                className="inputLog"
                                aria-label="email"
                                placeholder="E-mail"
                                aria-describedby="inputGroup-sizing-sm"
                                onChange={e => setEmail(e.target.value)}
                                name="email"
                                value={email}
                            />
                            <input
                                type='password'
                                className="inputLog"
                                placeholder="Senha"
                                onChange={e => setPassword(e.target.value)}
                                name="password"
                                value={password}
                            />
                            <div className="buttonLogin">
                                <button id="buttonLogin" >
                                    Acessar
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </Card >
        </div >
    )
}

export default Login