import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableAddons.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlinePlayCircle, AiOutlinePauseCircle } from 'react-icons/ai'
import { BsFillLockFill, BsFillUnlockFill, BsLockFill } from 'react-icons/bs';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import axios from "axios";
import { TbEyeSearch } from "react-icons/tb";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'cliente',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'endereco',
    numeric: false,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'telefone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function TableAddons() {
  const [rows, setRows] = React.useState([])
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);
  const [categorys, setCategorys] = React.useState([]);
  const [subcategorys, setSubcategorys] = React.useState([]);
  const [situation, setSituation] = React.useState();
  const [checked, setChecked] = React.useState(true);
  const [status, setStatus] = React.useState('Ativo')
  const [renovacao, setRenovacao] = React.useState('Não possui fatura')
  const [renovacaoLengh, setRenovacaoLengh] = React.useState(0)
  const [blockedResoller, setBlockerResoller] = React.useState([])

  // React.useEffect(() => {
  //   api
  //     .get('/TodasAddons')
  //     .then(response => {
  //       setRows(response.data);
  //     })
  // }, [])

  React.useEffect(() => {
    axios
      .get("https://api.clubedorevendedordegas.com.br/TodosUsuariosRevenda")
      .then((response) => {
        const res = response.data.slice(1)
        setRows(res.map((key) => ({
          id: key['id'], cliente: key['name'], type_reseller: key['type_reseller'], telefone: key['whatsapp'], plan: key['module'], email: key['email'], password: key['password'], reseller_name: key['reseller_name'], db: key['db_name']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    axios
      .get("https://api.clubedorevendedordegas.com.br/TodasRevendasBloqueadas")
      .then((response) => {
        setBlockerResoller(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      id: idModal,
      module: data.resellerPlan,
    }

    axios.put("https://api.clubedorevendedordegas.com.br/AtualizarUsuarioRevenda", dados)
      .then(response => {
        console.log(response)
      }).catch(err => {
        console.log(err)
      });

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {
    let statusClient = 0;

    if (situation == 1) {
      statusClient = 0;
    } else {
      statusClient = 1;
    }

    api.put('/AtualizarAddons', {
      id: idModal,
      status: statusClient
    })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}></button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do cliente</h4>
          </div>
          {rows.map(key => {
            if (key['id'] == idModal) {
              console.log(key)
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do cliente</td>
                      <td>{key['cliente']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">E-mail</td>
                      <td>{key['email']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Senha</td>
                      <td>{key['password']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Whatsapp</td>
                      <td> <InputMask className='inputMaskPainel2' mask="(99) 9 9999-9999" defaultValue={key['telefone']} disabled /></td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar addon do cliente
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}></button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Detalhes da addon do cliente</h4>
          </div>
          {rows.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome do cliente:</td>
                        <td>
                          <input
                            disabled
                            name="nameAddonEdit"
                            className="inputCadastro"
                            defaultValue={key['cliente']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Nome da revenda:</td>
                        <td>
                          <input
                            disabled
                            name="nameAddonEdit"
                            className="inputCadastro"
                            defaultValue={key['reseller_name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Plano da revenda:</td>
                        <td>
                          <Form.Select
                            name='resellerPlan'
                            className="inputCadastro"
                          >
                            <option value='' >Selecione um plano</option>
                            <option value='free' selected={key['plan'] == 'free'}>Gratuito</option>
                            <option value='1year' selected={key['plan'] == '1year'}>1 ano</option>
                          </Form.Select>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Situação do cliente
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}></button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja {situation == 1 ? ('desativar') : ('reativar')} o cliente selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const handleSwitch = (e, id) => {
    let checked = e.target.checked;
    let statusClient = '';
    if (checked) {
      statusClient = 1;
      setChecked(1)
      setStatus('Ativo')
    } else {
      statusClient = 0;
      setChecked(0)
      setStatus('Inativo')
    }

    api.put('/AtualizarAddons', {
      id: id,
      status: statusClient
    })
    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 500);

  }

  const handleBlockedReseller = (e, name, db) => {
    let switchBlockedChecked = e.target.checked;
    if (switchBlockedChecked) {
      axios.post('https://api.clubedorevendedordegas.com.br/BloquearRevenda', {
        name: name,
        db: db
      }).then(response => {
        console.log(response.data)
      }).catch(err => {
        console.log(err)
      })
    } else {
      axios.delete('https://api.clubedorevendedordegas.com.br/RemoverBloqueioRevenda/' + db)
        .then(response => {
          console.log(response.data)
        }).catch(err => {
          console.log(err)
        })
    }
  }

  const columns = [
    { title: "ID", field: "id" },
    { title: "Cliente", field: "cliente" },
    { title: "Revenda", field: "reseller_name" },
    { title: "Tipo de revenda", field: 'type_reseller' },
    {
      title: "Whatsapp", field: 'telefone', render: (rowData) => {
        let length = 11;
        if (rowData.telefone !== null) {
          length = rowData.telefone.length
        }
        return (
          <InputMask className='inputMaskPainel' mask={length == 10 ? "(99) 9999-9999" : length == 11 ? "(99) 9 9999-9999" : ""} defaultValue={rowData.telefone} />
        )
      }
    },
    {
      title: "Plano", field: 'plan', render: (rowData) => {
        return (
          <span>{rowData.plan == 'free' ? 'Plano gratuito' : rowData.plan == '1year' ? '1 Ano' : rowData.plan == '3month' ? '3 meses' : 'Plano gratuito'}</span>
        )
      }
    },
    {
      title: "Bloqueio", field: 'blocked', render: (rowData) => {
        const label = { inputProps: { 'aria-label': 'Switch demo' } };
        let blocked = false;
        blockedResoller.map(key => {
          if (key['name'] == rowData.reseller_name) {
            blocked = true
          }
        })
        return (
          <FormGroup>
            <FormControlLabel control={<Switch defaultChecked={blocked} />} onChange={e => {
              handleBlockedReseller(e, rowData.reseller_name, rowData.db)
            }} />
          </FormGroup>
        )
      }
    },
  ]

  return (
    <>
      <Loading />
      <ModalPainel />
      <ModalEdit />
      <ModalCancelar />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title="Revendas"
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <TbEyeSearch className='perfilAction' />,
                tooltip: 'Detalhes',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              // verifyPermission('delete_cupom') ? 
              // {
              //   icon: () => <AiOutlineDelete className='perfilAction' />,
              //   tooltip: 'Deletar',
              //   onClick: (event, rowData) => {
              //     setModalCancel(true)
              //     setIdModal(rowData.id)
              //   }
              // }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 30,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
