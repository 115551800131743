import { useState } from "react";
import React from 'react'
import './addons.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import makeAnimated from 'react-select/animated';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TableAddons from '../TableAddons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import axios from 'axios'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { set } from "lodash";
import Select from 'react-select'

const Addons = () => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [situation, setSituation] = useState('success')
    const [alertModal, setAlertModal] = useState(false)
    const typeCollaborator = sessionStorage.getItem("typeCollaborator");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userReseller, setUserReseller] = useState([])
    const [filteredReseller, setFilteredReseller] = useState([])

    var arrayReseller = []

    const animatedComponents = makeAnimated();

    React.useEffect(() => {
        axios
            .get("https://api.clubedorevendedordegas.com.br/TodosUsuariosRevenda")
            .then((response) => {
                const res = response.data.slice(1)
                setUserReseller(res)
                setFilteredReseller(res.map(key => ({
                    value: key['id'],
                    label: `${key['reseller_name']} - ${key['module'] == 'free' ? 'Gratuito' : '1 ano'}`
                })))
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);


    const submitModalConfirm = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        const clientPlan = data.clientType
        const clientMessage = data.clientMessage

        if (clientPlan == '1year' || clientPlan == 'free') {
            userReseller.map(key => {
                if (clientPlan == key['module']) {
                    axios
                        .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/clube_do_gas`,
                            {
                                number: "55" + key['whatsapp'] + "@s.whatsapp.net",
                                textMessage: {
                                    text: clientMessage
                                },
                                delay: 1200
                            }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                            }
                        })
                        .then((response) => {
                            // console.log(response);
                        })
                        .catch((err) => {
                            let ddd = String(key['whatsapp'][0]) + String(key['whatsapp'][1]);
                            let whatsPhone = key['whatsapp'].slice(3);

                            axios
                                .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/clube_do_gas`,
                                    {
                                        number: "55" + ddd + whatsPhone + "@s.whatsapp.net",
                                        textMessage: {
                                            text: clientMessage
                                        },
                                        delay: 1200
                                    }, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                                    }
                                })
                                .then((response) => {
                                    // console.log(response);
                                })
                                .catch((err) => {
                                    console.log(err);
                                })
                        })
                }
            })
        } else if (clientPlan == 'all') {
            userReseller.map(key => {
                axios
                    .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/clube_do_gas`,
                        {
                            number: "55" + key['whatsapp'] + "@s.whatsapp.net",
                            textMessage: {
                                text: clientMessage
                            },
                            delay: 1200
                        }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                        }
                    })
                    .then((response) => {
                        // console.log(response);
                    })
                    .catch((err) => {
                        let ddd = String(key['whatsapp'][0]) + String(key['whatsapp'][1]);
                        let whatsPhone = key['whatsapp'].slice(3);

                        axios
                            .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/clube_do_gas`,
                                {
                                    number: "55" + ddd + whatsPhone + "@s.whatsapp.net",
                                    textMessage: {
                                        text: clientMessage
                                    },
                                    delay: 1200
                                }, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                                }
                            })
                            .then((response) => {
                                // console.log(response);
                            })
                            .catch((err) => {
                                console.log(err);
                            })
                    })
            })
        } else if (clientPlan == 'personalizado') {
            userReseller.map(key => {
                arrayReseller.map(k => {
                    if (key['id'] == Number(k['value'])) {
                        axios
                            .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/clube_do_gas`,
                                {
                                    number: "55" + key['whatsapp'] + "@s.whatsapp.net",
                                    textMessage: {
                                        text: clientMessage
                                    },
                                    delay: 1200
                                }, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                                }
                            })
                            .then((response) => {
                                // console.log(response);
                            })
                            .catch((err) => {
                                let ddd = String(key['whatsapp'][0]) + String(key['whatsapp'][1]);
                                let whatsPhone = key['whatsapp'].slice(3);

                                axios
                                    .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/clube_do_gas`,
                                        {
                                            number: "55" + ddd + whatsPhone + "@s.whatsapp.net",
                                            textMessage: {
                                                text: clientMessage
                                            },
                                            delay: 1200
                                        }, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                                        }
                                    })
                                    .then((response) => {
                                        // console.log(response);
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    })
                            })
                    }
                })

            })
        }

        setMessage('Mensagem enviada com sucesso!')
        setAlertModal(true)
        setShow(false)
        setTimeout(() => {
            setAlertModal(false)
        }, 5000)
    }


    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleChangePlan = (e) => {
        const plan = e.target.value
        const divMultSelect = document.getElementById('divMultSelect')
        if (plan == 'personalizado') {
            divMultSelect.style.display = 'block'
        } else {
            divMultSelect.style.display = 'none'
        }
    }

    const handleReseller = (e) => {
        arrayReseller = e
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Enviar Mensagem'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Nova Mensagem</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitModalConfirm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="clientType">Plano do cliente *</InputLabel>
                                <Form.Select
                                    name='clientType'
                                    className="inputCadastro"
                                    defaultValue='all'
                                    onChange={handleChangePlan}
                                >
                                    <option value='' >Selecione um plano</option>
                                    <option value='all'>Todos os clientes</option>
                                    <option value='free'>Gratuitos</option>
                                    <option value='1year'>1 ano</option>
                                    <option value='personalizado'>Personalizado</option>
                                </Form.Select>
                            </div>
                            <div className="divCadastro" id='divMultSelect' style={{ display: 'none', marginTop: 10 }}>
                                <InputLabel htmlFor="clientType">Selecione as revendas</InputLabel>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={filteredReseller}
                                    onChange={handleReseller}
                                    isSearchable={true}
                                    name='resellerList'
                                    id='products'
                                />
                            </div>
                            <div className="divCadastro" style={{ marginTop: 10 }}>
                                <InputLabel htmlFor="clientType">Mensagem *</InputLabel>
                                <textarea rows="20" cols="40" name='clientMessage' />
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Enviar
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas >
            </>
        );

    }

    return (
        <>
            <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert variant="filled" severity={situation}>{message}</Alert>
            </Stack>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h3>Revendas</h3>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableAddons />
                </Card>
            </div >
        </>
    )

}

export default Addons